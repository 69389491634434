import { createStore } from 'vuex'

export default createStore({
  state: {
    title1:'客云云科技 · 让经纪人生活更美好',
    title2:'移动办公 合规平台 专业服务'
  },
  mutations: {
    setTitle1 (state, title1) {
      state.title1 =title1;
    },
    setTitle2 (state, title) {
      state.title2 =title;
    }
  },
  actions: {
  },
  modules: {
  }
})
