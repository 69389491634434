<template>
  <div >
      <img
      class="banner"
      src="@/assets/img/login/login_back.png"
    />
    <!-- <div class="fa-title">您的情况</div> -->
    <div class="fa">
      <div class="fa-div mt">
        <img  src="../assets/img/index/icon_people.png"/>
        <van-field
        @click="data.typeFlag=true"
        readonly="true"
        style="margin-left:0.3rem"
          v-model="data.selType.name"
          clearable
          placeholder="请选择身份"
        />
      </div>

      <div class="fa-div mt">
        <img  src="../assets/img/index/icon_phone3.png"/>
        <van-field
          style="margin-left:0.3rem"
          v-model="model.phone"
          clearable
          placeholder="请输入手机号"
        />
      </div>

      <div class="fa-div-yzm mt">
        <div class="fa-div" style="flex: 1;">
          <img style="width:1.3rem;" src="../assets/img/index/icon_shield3.png"/>
          <van-field
          style="border-radius:2rem;"
            v-model="model.sms"
            clearable
            maxlength="6"
            placeholder="请输入验证码"
          />
        </div>
        <van-button @click="clickSms()" style="width:10rem;margin-left:1rem;background-color:#3a8af2" round type="primary">{{data.smstxt}}</van-button>
      </div>
      <div class="mt" style="margin-top:3rem;">
        <van-button @click="clickFa()" class="fa-btn" style="background-color:#C90000;" size="large" round type="danger">登录/注册</van-button>
      </div>
      <div class="fa-div-radio" style="margin-top:1rem;">
        <div class="fa-chk">
          <img @click="changeChk('1')" v-if="model.chk!='1'" src="@/assets/img/index/icon_check.png"/>
          <img @click="changeChk('0')" v-if="model.chk=='1'" src="@/assets/img/index/icon_check_sel.png"/>
        </div>
        <div class="fa-label-xy">若您没有账号，此次登录将会直接注册账号，注册表示您已阅读并同意<a @click="clickXy(1402)">《用户协议》</a>、<a  @click="clickXy(1403)">《隐私协议》</a>及<a @click="clickXy(1404)">《内容平台协议》</a></div>
      </div>
    </div>
    <div class="service">
      <div class="service-title2">{{ getTitle1() }}</div>
      <div class="service-title3">{{getTitle2()}}</div>
    </div>
  </div>
  <van-action-sheet v-model:show="data.typeFlag">
  <van-picker
  title="请选择您的身份"
  :columns="data.columns"
  value-key="name"
  @confirm="clickType"
  @cancel="data.typeFlag=false"
/>
</van-action-sheet>
</template>

<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import http from '../common/bxhttp.js';
import {isNull,showMessage,getTitle1,getTitle2} from '../common/utils.js'
import {useRoute,useRouter} from 'vue-router'

let route=useRoute()
let router=useRouter()
let menuIndex={index:1}

function clickXy(code){
  localStorage.setItem("lphone",model.phone);
  localStorage.setItem("lsms",model.sms);
  router.push({ path: '/xy',query:{"newsCode":code} })
}

function clickType(item){
  data.selType=item;
  data.typeFlag=false;
}

let data=reactive({
    smstxt:"获取验证码",
    time:0,
    timer:'',
    selType:{"code":"U",name:"至尊用户"},
    columns:[{"code":"U",name:"至尊用户"},{"code":"I",name:"保险人员"},{"code":"S",name:"服务人员"}],
    typeFlag:false
})

let model=reactive({
    userType:"",
    phone:localStorage.getItem("lphone"),
    sms:localStorage.getItem("lsms"),
    flag_company:"",
    chk:'0'
})
function changeChk(flag){
  model.chk=flag;
}
//修改是否有公司
function changeFlagCompany(flag){
  model.flag_company=flag;
}
//获取短信验证码
function sms(){
    data.time--;
    data.smstxt='重新获取('+data.time+'s)';
    if(data.time == 0){
        clearInterval(data.timer);
        data.smstxt='重新获取';
    }
}

function clickSms(){
  if(isNull(model.phone)){
    showMessage("请输入手机号");
    return;
  }
  if(data.time>0){
    return;
  }
  http.post("/msg/web/smssend",model).then((res)=>{
        if(res.errcode=="0"){
          model.smsToken=res.detail;
          data.time=60;
          data.timer = setInterval(sms, 1000);
       
        }else{
          showMessage(res.errmsg);
        }
  })
  
}

function clickFa(){
  if(isNull(model.phone)){
    showMessage("请输入手机号");
    return;
  }
  if(isNull(model.sms)){
    showMessage("请输入验证码");
    return;
  }
  if(model.chk!="1"){
    showMessage("请阅读并同意协议");
    return;
  }
  model.userType=data.selType.code;
  if(localStorage.getItem('opentoken')){
    model.openToken=localStorage.getItem('opentoken');
  }else{
    model.openToken="";
  }
  localStorage.removeItem("lphone");
  localStorage.removeItem("lsms");
  http.post("/user/web/wxsmslogin",{
    "phone":model.phone,
    "imgKey":model.smsToken,
    "imgValue":model.sms,
    "userType":model.userType,
    "openToken":model.openToken
  }).then((res)=>{
        console.log(JSON.stringify(res))
        if(res.errcode=="0"){
          localStorage.setItem('token',res.token);
          localStorage.setItem('name',res.name);
          localStorage.setItem('type',res.type);
          localStorage.setItem('headimgurl',res.headimgurl);
          if(res.type=="U"&&!isNull(localStorage.getItem("shareProductCode"))&&!isNull(localStorage.getItem("shareOrderCode"))){
            router.push({ path: '/bxordershare',query:{"productCode":localStorage.getItem("shareProductCode"),"orderCode":localStorage.getItem("shareOrderCode")} })
          }else if(localStorage.getItem("destIndex")){
            var destUrl=localStorage.getItem("destIndex");
            localStorage.removeItem("destIndex");
            location.href=destUrl;
          }else{
            router.push({ path: "/bxindex" });
          }
        }else{
          showMessage(res.errmsg);
        }
  })

  
}
</script>

<style scoped lang="scss">

.banner{
  width:100%;
  height:auto;
}

.service{
  .service-title2{
    text-align: center;
    color: #848E9D;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 4rem;
  }

  .service-title3{
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}
.fa-title{
  margin:auto;
  text-align: center;
  font-size: 1.2rem;
  color: #225fb1;
}
.fa{
  margin: 1.2rem;

  .fa-btn{
    
  }
  .mt{
    margin-top:0.5rem;
  }
  .fa-div{
    border: 1px solid #949999;
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items:center;
    img{
      width: 1rem;
      height: 1.3rem;
      vertical-align: middle;
    }
  }

  .fa-div-yzm{
    display: flex;
  }

  .fa-chk{
    img{
      width:1rem;
    }
  }

  .fa-div-radio{
    margin-top:1rem;
    padding-left:1rem;
    display: flex;
    align-items: center;
    .fa-radio{
      width:0.8rem;
      height:0.8rem;
      border: 1px solid #ddd;
      border-radius: 50%;
      .fa-sel{
        width:0.6rem;
        height:0.6rem;
        margin:0.1rem;
        border-radius: 50%;
        background: #4372f6;
      }
    }
    .fa-label{
      font-size: 0.9rem;
      margin-left: 0.5rem;
      color:#0A1E3C;
    }
    .fa-label-xy{
      font-size: 0.7rem;
      margin-left: 0.5rem;
      color:#afb4bd;
      a{
        color:#3D6FFF;
      }
    }

     
  }
}
</style>

