import { Toast } from 'vant';
function isNull(str) {
  return str == undefined || str == "" || str == null;
}

function nullStr(str){
  return isNull(str)?"":str;
}

function isJSON(str) {
  if (typeof str == 'string') {
      try {
          JSON.parse(str);
          return true;
      } catch(e) {
          console.log(e);
          return false;
      }
  }
  return false;
}

//封装错误提示信息..
function showMessage(message, type = "error", showClose = true) {
  if(type=="error"){
    Toast.fail(message);
  }else{
    Toast.success(success);
  }
}

function formatDateMonth(date) {
    var y = date.getFullYear();
    console.log(y);
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m;
}

function disLabel(str,unit){
  if(isNull(str)){
    return ""
  }
  try{
    let labs=JSON.parse(str);
    let dis=""
    for(var i=0;i<labs.length;i++){
      if(!isNull(dis)){
        dis+="、";
      }
      dis+=labs[i];
    }
    return dis+nullStr(unit)
  }catch(e){

  }
}

function formatDateMonthSeconds(datetime){
  // var datetime = new Date();
  // datetime.setTime(time);
  var year = datetime.getFullYear();
  var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
  var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
  var hour = datetime.getHours()< 10 ? "0" + datetime.getHours() : datetime.getHours();
  var minute = datetime.getMinutes()< 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
  var second = datetime.getSeconds()< 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
  return year + "-" + month + "-" + date+" "+hour+":"+minute+":"+second;
}

function getInTimes(){
  var time=Date.now();
  localStorage.setItem("times",time);
}

function getStopTimes(){
  var time=Date.now();
  return time;
  // var intime=localStorage.getItem("times");
  // return time-intime;
}

function getTitleName(){
  let usertype=localStorage.getItem("userType");
  return usertype=="06"?"款小满":"客云云";
}

function getTitle1(){
  return "保险科技 · 让生活更美好";
}

function getTitle2(){
  return "移动办公 合规平台 专业服务";
}


export {isJSON,isNull,nullStr,showMessage,formatDateMonth,formatDateMonthSeconds,getInTimes,getStopTimes,disLabel,getTitleName,getTitle1,getTitle2}
