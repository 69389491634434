import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/bx/BxIndex.vue')
  },
  {
    path: '/code',
    name: 'code',
    component: () => import('../views/code/Code.vue')
  },
  {
    path: '/bxbind',
    name: 'BxBind',
    component: () => import('../views/bx/BxBind.vue')
  },
  {
    path: '/bxindex',
    name: 'BxIndex',
    component: () => import('../views/bx/BxIndex.vue')
  },
  {
    path: '/bxdetail',
    name: 'BxDetail',
    component: () => import('../views/bx/BxDetail.vue')
  },
  {
    path: '/bxme',
    name: 'BxMe',
    component: () => import('../views/bx/BxMe.vue')
  },
  {
    path: '/bxorderlist',
    name: 'BxOrderList',
    component: () => import('../views/bx/BxOrderList.vue')
  },
  {
    path: '/bxteamlist',
    name: 'BxTeamList',
    component: () => import('../views/bx/BxTeamList.vue')
  },
  {
    path: '/bxzxqk',
    name: 'BxZsqk',
    component: () => import('../views/bx/BxZsqk.vue')
  },
  {
    path: '/bxordershare',
    name: 'BxOrderShare',
    component: () => import('../views/bx/BxOrderShare.vue')
  },
  {
    path: '/bxservicelist',
    name: 'BxServiceList',
    component: () => import('../views/bx/BxServiceList.vue')
  },
  {
    path: '/bxserviceadd',
    name: 'BxServiceAdd',
    component: () => import('../views/bx/BxServiceAdd.vue')
  },
  {
    path: '/bxserviceorderlist',
    name: 'BxServiceOrderList',
    component: () => import('../views/bx/BxServiceOrderList.vue')
  },
  {
    path: '/bxserviceorderadd',
    name: 'BxServiceOrderAdd',
    component: () => import('../views/bx/BxServiceOrderAdd.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/code/Test.vue')
  },
  {
    path: '/code',
    name: 'code',
    component: () => import('../views/code/Code.vue')
  },
  {
    path: '/ccode',
    name: 'ccode',
    component: () => import('../views/code/CustomerCode.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/xy',
    name: 'xy',
    component: () => import('../views/Xy.vue')
  },
  
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/share/Share.vue')
  },

  {
    path: '/me',
    name: 'me',
    component: () => import('../views/me/Me.vue')
  },
  {
    path: '/meedit',
    name: 'meedit',
    component: () => import('../views/me/MeEdit.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/account/Account.vue')
  },
  
  {
    path: '/set',
    name: 'set',
    component: () => import('../views/set/Set.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/About.vue')
  },
  
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  // history: createWebHashHistory(),
  history:createWebHistory(),
  routes
})

export default router
